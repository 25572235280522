<template>
  <div>
    <portal to="body-top">
      <div v-if="kitForm.id">
        <h4 class="text-center font-weight-bolder">
          {{ formTitle }}
        </h4>
      </div>
    </portal>
    <form-features-wrapper
      :can-edit="{action: 'change', subject: 'Contacts'}"
      :is-update-form="isUpdateForm"
      :is-editable="isEditable"
      :is-pen-button="organizationRequiresEditConfirmation"
      @changeEditable="changeEditable"
    >
      <error-alert
        :fields="fields"
        :error="error"
      />
      <b-form>
        <validation-observer
          ref="kitsFormVal"
        >
          <b-row>
            <b-col md="6">
              <component
                :is="fields[field].type"
                v-for="field in ['name', 'discount']"
                :key="field"
                :ref="`${field}_ref`"
                v-model="kitForm[field]"
                v-bind="getProps(field)"
              />
            </b-col>
            <b-col md="6">
              <component
                :is="fields[field].type"
                v-for="field in ['is_active']"
                :key="field"
                :ref="`${field}_ref`"
                v-model="kitForm[field]"
                v-bind="getProps(field)"
              />
            </b-col>
          </b-row>
          <l-draggable-table-list-collector
            ref="lListCollectorTableRef"
            :module-name="MODULE_NAME"
            :table-columns="tableColumns"
            :fetched-data="kitForm.products"
            :is-footer="true"
            :table-config-options="{
              endpoint: `${MODULE_NAME}/getProducts`
            }"
            autosuggest-list-item-name="name"
            :table-suggestion-configs="autoSuggestTableColumns"
            :is-editable="this.isEditable"
            @getCollectedList="getCollectedList"
            @onDragEnd="onDragEnd"
          >
            <template #cell(action)="{ data }">
              <div class="d-flex align-items-center mx-md-n2 ">
                <feather-icon
                  icon="LEyeIcon"
                  size="22"
                  class="cursor-pointer mr-1 ml-2 border-dotted feather-trash-icon defaultIconColor"
                  @click="showProduct(data.id, $event)"
                />
                <feather-icon
                  icon="LTrashIcon"
                  size="22"
                  class="cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor"
                  @click="remove(data.id)"
                />
              </div>
            </template>
            <!--   Table Columns == == == == == == -->
            <template
              #cell(qty)="{ data }"
            >
              <BRow class="flex-nowrap align-items-center justify-content-around">
                <BCol
                  md="4"
                  class="pr-0"
                >
                  <button-dashed
                    type="button"
                    class="mr-0 button-dashed--disabled custom-size-icon"
                    :disabled="data.qty === minLimit || isEditable"
                    @click.stop="counterHandler(kitsFormProducts, data, 'qty','minus')"
                  >
                    <feather-icon
                      icon="LMinusIconBold"
                      size="32"
                    />
                  </button-dashed>
                </BCol>
                <BCol
                  md="4"
                  style="padding: 0 0px;"
                >
                  <b-form-input
                    v-model="data.qty"
                    class="custom-number-input"
                    :disabled="isEditable"
                    @input="countInputHandler($event)"
                  />
                </BCol>
                <BCol
                  md="4"
                  style="padding-left: 0px"
                >
                  <button-dashed
                    type="button"
                    class="ml-0 button-dashed--disabled custom-size-icon"
                    :disabled="data.qty === maxLimit || isEditable"
                    @click.stop="counterHandler(kitsFormProducts, data, 'qty')"
                  >
                    <feather-icon
                      icon="LPlusIconBold"
                      size="32"
                      class="font-weight-bolder"
                    />
                  </button-dashed>
                </BCol>
              </BRow>
            </template>
            <template
              #cell(rates)="{ data }"
            >
              <div class="mr-1 text-right">
                {{ getRate(data) | currency }}
              </div>
            </template>
            <template
              #cell(discounted)="{ data }"
            >
              <div class="mr-3 text-right">
                {{ (getRate(data) * ( 100 - Number(kitForm.discount || 0))) / 100 | currency }}
              </div>
            </template>
            <!--   Table Columns == == == == == == -->
            <!--   == == == == == == == == == ==   -->
            <!--   Footer Columns == == == == == == -->
            <!--qty, rates ,discounted-->
            <template #foot(sku)="">
              <div style="width: 300px" />
            </template>
            <template #foot(name)="">
              <div style="width: 300px" />
            </template>
            <template #foot(action)="">
              <div style="width: 100px" />
            </template>
            <template
              #foot(qty)=""
            >
              <div style="width: 150px">
                <BRow class="flex-nowrap align-items-center justify-content-around">
                  <BCol
                    md="4"
                  />
                  <div class="text-right">
                    {{ $t('total') | capitalize }}
                  </div>
                  <BCol
                    md="4"
                    class="text-center"
                  >
                    {{ sumTotal(kitsFormProducts, 'qty') }}
                  </BCol>
                  <BCol
                    md="4"
                  />
                </BRow>
              </div>
            </template>
            <template
              #foot(rates)=""
            >
              <div
                style="width: 155px"
                class="text-right"
              >
                {{ sumRatesTotal(kitsFormProducts) | currency }}
              </div>
            </template>
            <template
              #foot(discounted)=""
            >
              <div
                class="text-right"
                style="width: 150px"
              >
                {{ totalDiscountPrice() | currency }}
              </div>
            </template>
          <!--   Footer Columns == == == == == == -->
          <!--   == == == == == == == == == ==   -->
          </l-draggable-table-list-collector>
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged || !kitForm.id"
                class="cancelBtn font-weight-bolder"
                variant="outline-primary"
                @click="cancel"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </validation-observer>
      </b-form>
      <b-s-warning-modal ref="confirm-modal" />
    </form-features-wrapper>
    <!-- List Collector   -->
  </div>
</template>

<script>
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { required } from '@/libs/validations/validations'

import {
  BButton, BCol, BRow, BForm, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import LDraggableTableListCollector from '@/views/components/LDraggableTableListCollector.vue'
import router from '@/router'
import { ref } from '@vue/composition-api'
import store from '@/store'
import config from '../kitsConfig'

export default {
  name: 'KitsForm',
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    ErrorAlert,
    BFormInput,
    ButtonDashed,
    ValidationObserver,
    LDraggableTableListCollector,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: '',
      error: {},
      total: 0,
      maxLimit: 9999,
      minLimit: 1,
      totalRateSum: 0,
      isSubmit: false,
      required,
      isEditable: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    kitForm() {
      return this.$store.state[this.MODULE_NAME].kitForm
    },
    kitsFormProducts() {
      return this.$store.state[this.MODULE_NAME].kitForm.products
    },
    formTitle() {
      return this.kitForm.id ? this.kit.name : this.$t('Add a Kit')
    },
    kit() {
      return this.$store.state[this.MODULE_NAME].kit
    },
    products() {
      return this.$store.state[this.MODULE_NAME].products
    },
    catalog() {
      return this.$store.state.catalogs.catalog
    },
    kitFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].kitFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.kitForm) !== this.kitFormClone
    },
  },
  // watch: {
  //   kitsFormProducts,
  // },
  created() {
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_KIT_FORM_CLONE`, this.kitForm)
  },
  methods: {
    changeEditable() {
      this.isEditable = !this.isEditable
    },
    totalDiscountPrice() {
      const discount = +this.$store.state[this.MODULE_NAME].kitForm.discount
      // eslint-disable-next-line no-mixed-operators
      return this.totalRateSum - (this.totalRateSum * discount / 100)
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.isSubmit)
    },
    router() {
      return router
    },
    getCollectedList(list) {
      this.kitForm.products = list.map(item => ({
        ...item,
        qty: item.qty ? item.qty : 1,
      }))
    },
    onDragEnd(list) {
      this.kitForm.products = list
    },
    getRate(data) {
      if (data.rates.length) {
        const rate = data.rates.find(item => item.rate !== null)
        return rate ? rate.rate : 0
      }
      return 0
    },
    submit() {
      this.error = {}
      this.isSubmit = true

      this.$refs.kitsFormVal.validate().then(success => {
        if (success) {
          const data = this.kitForm

          const mappedProducts = data.products.map((product, index) => ({ id: product.id, qty: product.qty, position: index }))

          const kitForm = this.mappingFields(Object.keys(this.fields), data, {
            id: data.id ?? null,
            is_active: `${data.is_active}`,
            discount: data.discount ?? 0,
            products: mappedProducts,
          })

          this.sendNotification(this, kitForm, `${this.MODULE_NAME}/${this.kitForm.id ? 'update' : 'create'}`)
            .then(() => {
              this.redirectToKit()
            })
            .catch(err => {
              console.log(err)
              this.error = err.response?.data
            })
        }
      })
    },
    redirectToKit() {
      this.$router.push({ name: 'home-main-catalog-list-kits' })
    },
    cancel() {
      this.$router.push({ name: 'home-main-catalog-list-kits' })
    },
    loader() {
      this.$refs.kitsFormVal.reset()
      const smth = JSON.parse(this.kitFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    update(data) {
      this.$router.push({
        name: 'customer-update',
        params: { id: data.id },
      })
    },
    getValue(data, tableColumnName) {
      return data.item[tableColumnName]
    },
    getProps(fieldName) {
      return {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
        isEditable: this.isEditable,
      }
    },
    sumTotal(listForSummation = [], itemQty) {
      return listForSummation?.reduce(
        // eslint-disable-next-line radix
        (accumulator, currentValue) => {
          if (currentValue[itemQty]) {
            return accumulator + +currentValue[itemQty]
          }
          return accumulator + 0
        },
        0,
      )
    },
    sumRatesTotal(listForSummation = []) {
      if (!listForSummation.length) return
      // eslint-disable-next-line no-unused-vars
      let totalSum = 0
      const arrayOfRates = []

      for (let i = 0; i < listForSummation.length; i++) {
        const { rates, qty } = listForSummation[i]
        const validRates = rates.find(item => item.rate !== null)

        if (validRates) {
          arrayOfRates.push({ rate: validRates.rate, qty })
        }
      }
      totalSum += arrayOfRates?.reduce(
        // eslint-disable-next-line radix
        (accumulator, currentValue) => accumulator + (+currentValue.rate * currentValue.qty),
        0,
      )

      this.totalRateSum = totalSum
      // eslint-disable-next-line consistent-return
      return totalSum
    },
    countInputHandler($event) {
      if (Number($event) >= 10000) {
        $event.preventDefault()
      }
    },
    counterHandler(countNumbersArr, item, countItemKey = 'qty', countType = 'plus', countBy = 1) {
      // Distracting item
      const { id } = item

      // Finding handled item from countNumbersArr
      const getItem = countNumbersArr.find(countNumber => countNumber.id === id)
      // Converting Item Qty => Number Type
      getItem[countItemKey] = Number(getItem[countItemKey])

      // Checking countType
      if (countType === 'minus' && getItem[countItemKey]) {
        getItem[countItemKey] -= countBy
      } else if (countType === 'plus' && getItem[countItemKey] < 9999) {
        getItem[countItemKey] += countBy
      }
    },
    remove(data) {
      if (!this.isEditable) {
        this.kitForm.products = this.kitForm.products.filter(product => product.id !== data)
        this.totalDiscountPrice()
      }
    },
    showProduct(id, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({ name: 'home-catalog-view', params: { id } })
        window.open(route.href)
      } else {
        this.$router.push({ name: 'home-catalog-view', params: { id } })
      }
    },
  },

  setup(props) {
    // const isEditable = ref(props.isUpdateForm)
    const MODULE_NAME = 'kits'
    const CATALOG_MODULE_NAME = 'catalogs'
    const GET_PRODUCTS = 'list'
    const MODULE_NAME_CLONE = 'cloneData'
    const { tableColumns, fields, autoSuggestTableColumns } = config()

    return {
      // isEditable,
      fields,
      MODULE_NAME,
      tableColumns,
      GET_PRODUCTS,
      MODULE_NAME_CLONE,
      CATALOG_MODULE_NAME,
      autoSuggestTableColumns,
    }
  },

}
</script>
<style scoped lang="scss">
.custom-number-input {
  margin: 0 auto;
  text-align: center;
  height: 30px;
  width: 50px;
  padding-left: 5px;
  padding-right: 5px;
}

.button-dashed--disabled {
  &:disabled {
    opacity: 0.5;
  }
}

.custom-size-icon {
    width: 35px;
    height: 27px;
    padding: 2px 8px;
    margin-left: auto;
    margin-right: auto;
}

</style>
